import React, { useEffect, useRef } from "react";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  Legend,
  PointElement,
  Tooltip,
} from "chart.js";
import tokens from "@tbml/tokens";
import {
  TonalityDataPoint,
  VisibilityAndTonalityDataPoint,
} from "@tbml/api-interface/graphql";
import { H4 } from "../Typography";

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  LineController,
  PointElement,
  Tooltip
);
Chart.defaults.font.family = tokens.font.h1.fontFamily.value;

const GRAPH_COLORS = {
  BLUE: "#38A3EB",
  GREEN: "#34B82A",
  RED: "#FF0000",
  GRAY: "#BEBEBE",
};
const MAX_BAR_THICKNESS = 30;

type TonalityFilters = {
  entity: string[];
  region: string[];
};
type Props = {
  addVisibility?: boolean;
  data: TonalityDataPoint[] | VisibilityAndTonalityDataPoint[];
  title: string;
  tonalityFilters?: TonalityFilters;
};

export default function TonalityWidget({
  addVisibility = false,
  data,
  title,
  tonalityFilters = { entity: [], region: [] },
}: Props): JSX.Element | null {
  const canvasEl = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const ctx = canvasEl.current;
    if (!ctx) {
      return () => {
        // nothing to clean
      };
    }
    data.sort((a, b) => a.date.localeCompare(b.date));
    const tonalityGrouped = data.map(
      ({ noPositive, noNeutral, noNegative, ...point }) => {
        const total = noPositive + noNegative + noNeutral;
        const positiveRatio =
          total > 0 ? Math.round((noPositive / total) * 100) : 0;
        const negativeRatio =
          total > 0 ? Math.round((noNegative / total) * 100) : 0;
        const neutralRatio =
          total > 0 ? Math.round((noNeutral / total) * 100) : 100;

        return {
          positive: positiveRatio,
          negative: negativeRatio,
          neutral: neutralRatio,
          ...(addVisibility && "visibility" in point
            ? { visibility: point.visibility }
            : {}),
        };
      }
    );

    const hasSignificantData = data.some(
      ({ noPositive, noNegative }) => noPositive > 0 || noNegative > 0
    );

    if (!hasSignificantData) {
      const drawCtx = ctx.getContext("2d");
      const hasActiveFilters = !!(
        tonalityFilters.entity.length || tonalityFilters.region.length
      );

      const message = hasActiveFilters
        ? "No data for filters applied"
        : "No data for selected time period";

      if (drawCtx) {
        drawCtx.clearRect(0, 0, ctx.width, ctx.height);
        drawCtx.textAlign = "center";
        drawCtx.textBaseline = "middle";
        drawCtx.font = `16px ${tokens.font.longTextSmall.fontFamily.value}`;
        drawCtx.fillText(message, ctx.width / 2, ctx.height / 2);
      }
      return () => {};
    }

    const chart = new Chart(ctx, {
      plugins: [
        {
          id: "noDataPlugin",
          beforeDraw(inputChart) {
            if (
              inputChart.data.datasets.length === 0 ||
              inputChart.data.datasets.every(
                (dataset) => dataset.data.length === 0
              )
            ) {
              const { ctx: drawCtx } = inputChart;
              const { width } = inputChart;
              const { height } = inputChart;

              inputChart.clear();

              drawCtx.save();
              drawCtx.textAlign = "center";
              drawCtx.textBaseline = "middle";
              drawCtx.font = `16px ${tokens.font.longTextSmall.fontFamily.value}`;
              drawCtx.fillText("No data to display", width / 2, height / 2);
              drawCtx.restore();
            }
          },
        },
      ],
      data: {
        labels: data.map((i) => i.date),
        datasets: [
          ...(addVisibility
            ? [
                {
                  type: "line" as const,
                  label: "Visibility",
                  data: tonalityGrouped.map((i) => i.visibility),
                  yAxisID: "y1",
                  borderColor: GRAPH_COLORS.BLUE,
                  borderWidth: 3,
                  tension: 0.1,
                },
              ]
            : []),
          {
            type: "bar",
            label: "Positive",
            data: tonalityGrouped.map((i) => i.positive),
            backgroundColor: GRAPH_COLORS.GREEN,
            maxBarThickness: MAX_BAR_THICKNESS,
          },
          {
            label: "Neutral",
            type: "bar",
            data: tonalityGrouped.map((i) => i.neutral),
            backgroundColor: GRAPH_COLORS.GRAY,
            maxBarThickness: MAX_BAR_THICKNESS,
          },
          {
            label: "Negative",
            type: "bar",
            data: tonalityGrouped.map((i) => i.negative),
            backgroundColor: GRAPH_COLORS.RED,
            maxBarThickness: MAX_BAR_THICKNESS,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: { display: false },
            border: { color: "transparent" },
          },
          y: {
            stacked: true,
            grid: { display: false },
            border: { color: "transparent" },
            title: {
              text: "Tonality",
            },
          },
          ...(addVisibility
            ? {
                y1: {
                  type: "linear",
                  display: true,
                  position: "right",
                  title: {
                    text: "Visibility",
                  },
                  grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                },
              }
            : {}),
        },
        plugins: {
          tooltip: {
            enabled: true,
            mode: "index",
            usePointStyle: true,
            boxHeight: 8,
            callbacks: {
              labelPointStyle(context) {
                const value = context.raw || 0;
                return value !== 0 && value !== 100
                  ? { pointStyle: "circle", rotation: 0 }
                  : undefined;
              },
              label(tooltipItem) {
                const addPercentage =
                  tooltipItem.dataset.label !== "Visibility";
                return `${tooltipItem.dataset.label
                  ?.substring(0, 1)
                  .toUpperCase()}${tooltipItem.dataset.label?.substring(1)} ${
                  tooltipItem.raw
                }${addPercentage ? "%" : ""}`;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              boxHeight: 6,
              font: {
                size: 14,
              },
              padding: 10,
            },
          },
        },
      },
    });
    return () => chart.destroy();
  }, [
    addVisibility,
    data,
    tonalityFilters.entity.length,
    tonalityFilters.region.length,
  ]);

  return (
    <div style={{ maxHeight: "400px", maxWidth: "550px" }}>
      <H4>{title}</H4>
      <canvas ref={canvasEl} width="550" height="400" />
    </div>
  );
}
