import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { gql } from "graphql-request";
import { OpenInNew } from "@mui/icons-material";
import {
  useSkeleton,
  SkeletonContext,
} from "@tbml/shared-dependencies/react-skeletons";
import { Article } from "@tbml/hooks/useArticles";
import { useCurrentCustomerId } from "@tbml/hooks/useCurrentCustomerId";
import { useCustomers } from "@tbml/hooks/useCustomers";
import { Spacer } from "@tbml/components/Spacer";
import { ArticleMeta } from "@tbml/components/ArticleMeta";
import { Skeleton } from "@tbml/components/Skeleton";
import { LicenseExpired } from "@tbml/components/LicenseExpired";
import { AnimatedLinkProps, Link } from "@tbml/components/Link";
import { LicenseState } from "@tbml/api-interface/licence";
import {
  ArticleSlideLine,
  ArticleSlideBody,
  ArticleSlideWrapper,
  ContentWrapper,
  HoverH5,
  ARTICLE_SLIDE_LINE_WIDTH,
  ARTICLE_SLIDE_LINE_HEIGHT,
} from "./styles";

export const customerFragmentName = "CustomerSlideFields";
export const customerFragment = gql`
  fragment CustomerSlideFields on Customer {
    id
    language
  }
`;

export type Props = {
  article?: Article;
  hovered?: boolean;
  isSelected?: boolean;
  licenseState?: LicenseState;
} & Partial<Pick<AnimatedLinkProps, "href" | "animation">>;

export function ArticleSlide({
  article,
  hovered: hoveredProp,
  isSelected,
  licenseState = "licensed",
  href = undefined,
}: Props): JSX.Element {
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    setHovered(!!hoveredProp);
  }, [hoveredProp]);

  const [customerId, setCustomerId] = useCurrentCustomerId();
  const { query } = useCustomers();
  const { data: customers, status: customerStatus } = query({
    filter: customerId ? { ids: [customerId] } : {},
    fragment: customerFragment,
    fragmentName: customerFragmentName,
    enabled: !!article,
  });

  const [customer] = customers ?? [];

  useEffect(() => {
    if (!customer) return;
    if (customerId === customer.id) return;
    setCustomerId(customer.id);
  }, [setCustomerId, customer, customerId]);

  const ArticleTileSkeleton = useCallback(
    () => (
      <ArticleSlideWrapper isSelected={isSelected}>
        <Skeleton
          width={ARTICLE_SLIDE_LINE_WIDTH}
          height={ARTICLE_SLIDE_LINE_HEIGHT}
        />
        <Spacer size="verticalM" />
        <Skeleton width="240px" forText="h4" />
        <Spacer size="verticalM" />
        <SkeletonContext.Provider value>
          <ArticleMeta showAuthor relativeTime />
        </SkeletonContext.Provider>
        <Spacer size="verticalM" />
        <Skeleton width="150px" forText="shortTextSmall" />
        <Spacer size="verticalM" />
        <Skeleton width="240px" forText="longTextSmall" />
      </ArticleSlideWrapper>
    ),
    [isSelected]
  );

  const { withSkeleton } = useSkeleton({
    isLoading: (isParentSkeletonLoading) =>
      article
        ? isParentSkeletonLoading || customerStatus === "pending"
        : isParentSkeletonLoading,
    Skeleton: ArticleTileSkeleton,
  });

  const articleDelta = useMemo(() => {
    if (!article || !customer) return { ops: [] };

    if (article.deltaSnippet) {
      return article.deltaSnippet;
    }
    return null;
  }, [article, customer]);

  function PreviewWrapper({ children }: PropsWithChildren): JSX.Element {
    return <div>{children}</div>;
  }

  return withSkeleton(
    <PreviewWrapper>
      <ArticleSlideWrapper
        isSelected={isSelected}
        onMouseEnter={() => {
          if (licenseState !== "licensed" && !articleDelta) return;
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <ArticleSlideLine />
        <Spacer size="verticalM" />
        {article && href ? (
          <Link
            fontSize="inherit"
            href={href}
            onClick={() => {
              // this low level method ensures the app does need to update because of this
              window.history.replaceState(
                {
                  ...window.history.state,
                  as: window.history.state.as.concat("#addcov"),
                },
                "",
                "/#addcov"
              );
            }}
            aria-label={`Link to the article: "${
              article.customHeadline || article.headline || "article"
            }"`}
            title={`${article.deeplink}`}
          >
            <HoverH5 style={{ cursor: "pointer" }} hover={hovered}>
              {article.customHeadline || article.headline}
            </HoverH5>
          </Link>
        ) : (
          <Skeleton width="240px" forText="h5" />
        )}
        <Spacer size="verticalM" />
        <ArticleMeta article={article} showAuthor />
        <Spacer size="verticalM" />
        <ContentWrapper aria-label={`Content of ${article?.id}`}>
          {articleDelta && (
            <ArticleSlideBody
              editorialContent={articleDelta}
              renderedLines={3}
              clampLines={3}
              font="shortTextSmall"
            />
          )}
          {!articleDelta && licenseState === "archived" && (
            <LicenseExpired
              text="License has expired"
              originalArticleUrl={article?.deeplink}
            />
          )}
          {article?.deeplink && (
            <Link
              underline="always"
              fontSize="inherit"
              href={article.deeplink}
              target="_blank"
              aria-label={`Link to original article ${article.deeplink}`}
              title={article.deeplink}
            >
              Link to original article
              <OpenInNew fontSize="inherit" />
            </Link>
          )}
        </ContentWrapper>
      </ArticleSlideWrapper>
    </PreviewWrapper>
  );
}
