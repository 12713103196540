import React from "react";
import { Box, styled } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { QueryStatus } from "@tanstack/react-query";
import { TbmlTheme } from "@tbml/components/Theme";
import { Container } from "@tbml/components/PageLayout";
import {
  ExecutiveBriefingTopic,
  ArticleSortSection,
} from "@tbml/api-interface/graphql";
import { Spacer } from "@tbml/components/Spacer";
import { Issue } from "@tbml/hooks/useIssues";
import { Article } from "@tbml/hooks/useArticles";
import { DynamicTopic } from "../Topic/dynamic";
import { hasInboxTimeoutError } from "../AdditionalCoverage";
import { DynamicAdditionalCoverage } from "../AdditionalCoverage/dynamic";
import { SocialMediaSection } from "../SocialMediaSection";
import { AnalyticsSection } from "../AnalyticsSection";

export type Props = {
  filteredTopics?: ExecutiveBriefingTopic[];
  isSmallerThanMd?: boolean;
  hiddenSortSectionsForAddcov?: ArticleSortSection[];
  issue?: Issue;
  backgroundColor?: string;
  showSocialMediaSection?: boolean;
  socialMediaThemeVariant?: "regular" | "alt";
  socialMediaArticles?: Article[];
  socialMediaArticlesStatus?: QueryStatus;
  setInView?: (inView: boolean) => void;
};

const IntersectionTrigger = styled("span")``;

const getAddBorder = (index: number, isSmallerThanMd: boolean) => {
  if (isSmallerThanMd) return undefined;
  return index % 2 ? "right" : "left";
};

export function AnalyticsRenderSection({
  issue = undefined,
  backgroundColor = "",
}: {
  issue?: Issue;
  backgroundColor?: string;
}): JSX.Element | null {
  return issue?.analyticsSectionTitle && issue?.analyticsSummary ? (
    <Container
      position="static"
      sx={{
        backgroundColor,
        paddingTop: 4,
      }}
    >
      <AnalyticsSection
        analyticsSummary={issue.analyticsSummary}
        analyticsTitle={issue.analyticsSectionTitle}
        sentimentData={{
          data: issue.widgetsData?.sentiment?.points ?? [],
          caption: issue.widgetsData?.sentiment?.caption ?? "",
          enabled: issue.widgetsData?.sentiment?.enabled ?? false,
          rangeStart: issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
          rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
        }}
        tonalityData={{
          data: issue.widgetsData?.tonality?.points ?? [],
          caption: issue.widgetsData?.tonality?.caption ?? "",
          enabled: issue.widgetsData?.tonality?.enabled ?? false,
          rangeStart: issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
          rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
        }}
        visibilityAndTonalityData={{
          data: issue.widgetsData?.visibilityAndTonality?.points ?? [],
          caption: issue.widgetsData?.visibilityAndTonality?.caption ?? "",
          enabled: issue.widgetsData?.visibilityAndTonality?.enabled ?? false,
          rangeStart: issue.widgetsData?.widgetsDateRangeStart ?? new Date(),
          rangeEnd: issue.widgetsData?.widgetsDateRangeEnd ?? new Date(),
        }}
        analyticsActionButtonIncluded={
          issue.analyticsActionButtonIncluded ?? false
        }
        analyticsActionButtonName={issue.analyticsActionButtonName ?? ""}
        analyticsActionButtonLink={issue.analyticsActionButtonLink ?? ""}
      />
    </Container>
  ) : null;
}
export function ExecutiveSection({
  filteredTopics = [],
  isSmallerThanMd = false,
  setInView = () => {},
}: {
  filteredTopics?: ExecutiveBriefingTopic[];
  isSmallerThanMd?: boolean;
  setInView?: (inView: boolean) => void;
}): JSX.Element {
  const { ref: topicRef, inView: topicInView } = useInView({
    triggerOnce: true,
  });

  React.useEffect(() => {
    setInView(topicInView);
  }, [topicInView, setInView]);

  const renderTopics = React.useMemo(
    () =>
      filteredTopics?.map(
        ({ stories, id: topicId, title, subTitle, showStoryOnly }, index) => (
          <TbmlTheme
            key={topicId}
            variant={index % 2 === 0 ? "regular" : "alt"}
          >
            <Container
              aria-label={`Topic Container ${index}`}
              addBorder={getAddBorder(index, isSmallerThanMd) ?? undefined}
              position="static"
            >
              <DynamicTopic
                title={title}
                subtitle={subTitle ?? undefined}
                stories={stories ?? []}
                showStoryOnly={showStoryOnly}
              />
              {index === 0 && <IntersectionTrigger ref={topicRef} />}
            </Container>
          </TbmlTheme>
        )
      ),
    [filteredTopics, isSmallerThanMd, topicRef]
  );

  return <div>{renderTopics}</div>;
}

export function CoverageSection({
  issue = undefined,
  filteredTopics = [],
  hiddenSortSectionsForAddcov = [],
}: {
  issue?: Issue;
  filteredTopics?: ExecutiveBriefingTopic[];
  hiddenSortSectionsForAddcov?: ArticleSortSection[];
}): JSX.Element | null {
  if (!issue) return null;

  const shouldRender =
    issue.additionalCoverage || hasInboxTimeoutError(issue.errors ?? []);
  if (!shouldRender) return null;

  const variant = filteredTopics.length % 2 === 0 ? "regular" : "alt";

  return (
    <TbmlTheme variant={variant}>
      <Container position="static">
        <DynamicAdditionalCoverage
          hasScrollSection
          hiddenSortSectionsForAddcov={hiddenSortSectionsForAddcov}
          issueId={issue.id}
          hasInboxTimeout={hasInboxTimeoutError(issue.errors ?? [])}
        />
        <Spacer size="verticalL" />
      </Container>
    </TbmlTheme>
  );
}

export function SocialSection({
  socialMediaArticles = [],
  socialMediaArticlesStatus = "pending",
  showSocialMediaSection = false,
  socialMediaThemeVariant = "regular",
  setInView = () => {},
}: {
  socialMediaArticles?: Article[];
  socialMediaArticlesStatus?: QueryStatus;
  showSocialMediaSection?: boolean;
  socialMediaThemeVariant?: "regular" | "alt";
  setInView?: (inView: boolean) => void;
}): JSX.Element {
  const { ref: socialMediaSectionRef, inView: socialMediaSectionInView } =
    useInView({
      triggerOnce: true,
    });
  React.useEffect(() => {
    setInView(socialMediaSectionInView);
  }, [socialMediaSectionInView, setInView]);
  const renderTopics = React.useMemo(
    () => (
      <Box ref={socialMediaSectionRef}>
        {showSocialMediaSection && (
          <TbmlTheme variant={socialMediaThemeVariant}>
            <Container position="static">
              <Spacer size="verticalXxl" />
              <SocialMediaSection
                articles={socialMediaArticles}
                articlesStatus={socialMediaArticlesStatus}
              />
              <Spacer size="verticalL" />
            </Container>
          </TbmlTheme>
        )}
      </Box>
    ),
    [
      showSocialMediaSection,
      socialMediaArticles,
      socialMediaArticlesStatus,
      socialMediaSectionRef,
      socialMediaThemeVariant,
    ]
  );
  return <div>{renderTopics}</div>;
}
export const sectionComponentsMap: { [key: string]: React.FC<Props> } = {
  analytics: AnalyticsRenderSection,
  executive: ExecutiveSection,
  coverage: CoverageSection,
  social: SocialSection,
};
